
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink, RouteName } from "@/router";
import { IRoomForm, Room, RoomBed, RoomForm } from "@/types/room";
import { roomsModule } from "@/store/rooms/rooms";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import AboutRoomForm from "@/views/EditRoom/components/AboutRoomForm.vue";
import RoomCapacityForm from "@/views/EditRoom/components/RoomCapacityForm.vue";
import RoomAmenitiesForm from "@/views/EditRoom/components/RoomAmenitiesForm.vue";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import { mediaModule } from "@/store/media_objects/media_objects";
import { copyMediaObject, EntityNameEnum } from "@/api/media_objects/media_objects";
import DeleteRoom from "@/views/EditRoom/components/DeleteRoom.vue";
import { editRoomBed, editRoomVariant } from "@/api/rooms/rooms";
import { cloneDeep } from "lodash";
import MediaPicker from "@/views/EditRoom/components/MediaPicker.vue";
import { EditHotelSectionType } from "@/views/EditHotel/EditHotel.vue";
import Breadcrumbs, { Breadcrumb } from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import RoomVariants from "@/views/EditRoom/components/RoomVariants.vue";
import { RoomVariant } from "@/types/roomVariant";

enum EditRoomSectionType {
    ABOUT = "ABOUT",
    VARIANTS = "VARIANTS",
    AMENITIES = "AMENITIES",
    GALLERY = "GALLERY",
    DELETE = "DELETE"
}

interface EditRoomSection {
    name: string,
    type: EditRoomSectionType
}

@Component({
    components: {
        RoomVariants,
        Breadcrumbs,
        MediaPicker,
        DeleteRoom,
        CustomUploader,
        RoomAmenitiesForm,
        RoomCapacityForm,
        Spinner,
        AboutRoomForm,
        Button,
    },
})
export default class EditRoom extends Vue {
    EditRoomSectionType = EditRoomSectionType;
    ButtonType = ButtonType;

    sections: Array<EditRoomSection> = [
        {
            name: "Osnovne informacije",
            type: EditRoomSectionType.ABOUT,
        },
        {
            name: "Varijante",
            type: EditRoomSectionType.VARIANTS,
        },
        {
            name: "Sadržaj",
            type: EditRoomSectionType.AMENITIES,
        },
        {
            name: "Fotografije",
            type: EditRoomSectionType.GALLERY,
        },
    ];
    selectedSection = EditRoomSectionType.ABOUT;

    roomForm = new RoomForm();
    newUploads: Array<UploadedFile> = [];
    originalRoomQuantity = 1;
    roomQuantity = 1;
    uploadAsync = false;
    mediaPickerOpened = false;
    copyMediaAsync = false;
    breadcrumbs: Array<Breadcrumb> = [];

    get showActionButtons(): boolean {
        return this.selectedSection !== EditRoomSectionType.DELETE && this.selectedSection !== EditRoomSectionType.VARIANTS;
    }

    get selectedRoom(): Room | null {
        return roomsModule.selectedRoom;
    }

    get fetchAsync(): boolean {
        return roomsModule.fetchAsync;
    }

    get editAsync(): boolean {
        return roomsModule.addEditAsync;
    }

    get getHotelMedia(): Array<MediaObject> {
        return this.selectedRoom?.hotel?.mediaObjects || [];
    }

    selectSection(section: EditRoomSectionType): void {
        this.selectedSection = section;
    }

    backToRooms(): void {
        goBackOrPush(RouteLink.HotelContent);
    }

    removeUpload(file: UploadedFile): void {
        this.newUploads = this.newUploads.filter(i => i.url !== file.url);
    }

    onRemoveMediaObject(media: MediaObject): void {
        this.roomForm.mediaObjects = (this.roomForm.mediaObjects || []).filter(m => typeof m === "string" ? m !== media.id : m.id !== media.id);
    }

    addNewUploads(files: Array<UploadedFile>): void {
        this.newUploads.push(...files);
    }

    async addMediaFromHotel(mediaObjectIds: Array<string>): Promise<void> {

        this.copyMediaAsync = true;

        const promises: Array<Promise<MediaObject | null>> = [];

        if (this.selectedRoom) {
            mediaObjectIds.forEach(id => {
                promises.push(copyMediaObject(id, {
                    entityId: this.selectedRoom?.id || "",
                    entityName: EntityNameEnum.Room,
                }).then(mediaObject => {

                    if (mediaObject !== null) {
                        if (!this.roomForm.mediaObjects){
                          this.roomForm.mediaObjects = [];
                        }
                        this.roomForm.mediaObjects.push(mediaObject);
                    }

                    return mediaObject;
                }));
            });
        }

        await Promise.allSettled(promises).finally(() => {
            this.copyMediaAsync = false;
            this.mediaPickerOpened = false;
        });
    }

    async onSubmit(): Promise<void> {
        if (!this.roomForm.validate()) return;

        if (this.selectedRoom) {

            this.roomForm.prepareFormForSending();

            const diff = this.roomForm.difference(RoomForm.createFormWithRoom(this.selectedRoom)) as IRoomForm;
            diff.q = +this.roomQuantity;
            diff.amenities = this.roomForm.amenities.map(m => {
                if (m.startsWith("/api")) {
                    return m;
                } else {
                    return `/api/amenities/${m}`;
                }

            });

            if (Array.isArray(diff.mediaObjects)) {
                diff.mediaObjects = diff.mediaObjects.map(m => {
                    if (typeof m === "string") {
                        if (m.startsWith("/api")) {
                            return m;
                        } else {
                            return `/api/media_objects/${m}`;
                        }
                    } else {
                        return `/api/media_objects/${m.id}`;
                    }
                });
            }


            if (diff.hotel && !diff.hotel?.startsWith("/api")) {
                diff.hotel = `/api/hotels/${diff.hotel}`;
            }

            try {

                // First edit room iself, so new media wont be overriden
                await roomsModule.editRoomAction({ roomId: this.selectedRoom.id, data: diff });

                // Then we upload new images to prevent edit action override mediaObjects
                if (this.newUploads.length) {

                    const promises: Array<Promise<MediaObject | null>> = [];

                    const roomId = this.selectedRoom.id;

                    this.uploadAsync = true;

                    this.newUploads.forEach(upload => {
                        if (!upload.progress) {                            // Check if already uploading
                            promises.push(mediaModule.uploadMediaObject({
                                file: upload,
                                entityData: {
                                    entityName: EntityNameEnum.Room,
                                    entityId: roomId,
                                },
                            }).then(mediaObj => {

                                if (mediaObj !== null) {          // successful upload
                                    if (!this.roomForm.mediaObjects) {
                                      this.roomForm.mediaObjects = [];
                                    }
                                    this.roomForm.mediaObjects.push(mediaObj);
                                    this.removeUpload(upload);
                                }

                                return mediaObj;
                            }));
                        }
                    });

                    await Promise.allSettled(promises).then(() => this.uploadAsync = false);
                }
            } catch (ex) {
                //
            }
        }
    }

    async beforeMount() {

        const { params: { roomId } } = this.$route;


        if (roomId) {
            await roomsModule.fetchRoomByIdAction(roomId);
        }

        if (this.selectedRoom) {

            this.setData(this.selectedRoom);

            // set once on mount
            this.originalRoomQuantity = cloneDeep(this.selectedRoom.q || 1);
            this.roomQuantity = cloneDeep(this.selectedRoom.q || 1);
            this.breadcrumbs = [
                {
                    label: "Hoteli",
                    route: RouteLink.HotelContent,
                },
                {
                    label: this.selectedRoom.hotel?.name || "",
                    route: RouteName.EditHotel,
                    routeMeta: {
                        hotelId: this.selectedRoom.hotel?.id ?? "",
                        tab: EditHotelSectionType.ROOMS,
                    },
                },
                {
                    label: this.selectedRoom.name,
                    route: this.$route.path,
                },
            ];
        }
    }

    async refreshRoomData(): Promise<void> {
        if (this.selectedRoom) {
            await roomsModule.fetchRoomByIdAction(this.selectedRoom.id);
            this.setData(this.selectedRoom);
        }
    }

    setData(room: Room): void {
        this.roomForm = RoomForm.createFormWithRoom(room);
    }

}
